import React from 'react'
import { Link } from 'react-router-dom';
import Logo from '../assets/img/logo.svg'


const  Nav = () => {
  const hamburgerToggler = () => {
    const btn = document.querySelector('#menu-btn')
    const nav = document.querySelector('#menu')

      btn.classList.toggle('open')
      nav.classList.toggle('flex')
      nav.classList.toggle('hidden')
    
  }

  const currentTab = (path) => {
    if(window.location.pathname === path){
      return {color: "#004D56",textShadow:"0px 0px 2px rgba(63,0,113,0.2)"};
    }else{
      return {color:"#444"};
    }
  }


  return (

    <nav class="relative container mx-auto p-6">
      {/* <!-- Flex container --> */}
      <div class="flex items-center justify-between">
        {/* <!-- Logo --> */}
        <div class="pt-2">
          {/* <img src={Logo} alt="" width="150" /> */}
          <h1 className="text-3xl font-extrabold">
            <Link to="/" class="my-logo" >SHAMGAR</Link>
          </h1>
        </div>
        {/* <!-- Menu Items --> */}
        <div class="hidden space-x-6 md:flex">
          <Link to="/" style={currentTab("/")} className="hover:text-darkGrayishBlue">Home</Link>
          <Link to="/cv" style={currentTab("/cv")}  className="hover:text-darkGrayishBlue">Cv</Link>
          <Link to="/contact" style={currentTab("/contact")}  className="hover:text-darkGrayishBlue">Contact</Link>
        </div>
        
        <button
          id="menu-btn"
          class="block hamburger md:hidden focus:outline-none" onClick ={() => hamburgerToggler()}
        >
          <span class="hamburger-top"></span>
          <span class="hamburger-middle"></span>
          <span class="hamburger-bottom"></span>
        </button>
      </div>

      {/* <!-- Mobile Menu --> */}
      <div class="md:hidden">
        <div
          id="menu"
          class="absolute flex-col items-center hidden self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md"
        >
         <Link to="/" style={currentTab("/")} className="hover:text-darkGrayishBlue">Home</Link>
          <Link to="/cv" style={currentTab("/cv")} className="hover:text-darkGrayishBlue">Cv</Link>
          <Link to="/contact" style={currentTab("/contact")} className="hover:text-darkGrayishBlue">Contact</Link>
        </div>
      </div>
    </nav>

  )
}

export default  Nav