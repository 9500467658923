// import { directive } from '@babel/types'
import React, { useEffect, useState } from 'react'
import '../index.css';
import About from './About';
import Contact from './Contact';
import Education from './Education';
import Footer from './Footer';
import Main from './Main';
import Nav from './Nav';
// import Skills from './Skills';
// import Verse from './Verse';
import Work from './Work';
import BarLoader from "react-spinners/BarLoader";
// import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';



const Home = () => {

  const [loading, setLoading] = useState(true);
    
  useEffect(() => {
      setLoading(true);
      setTimeout(() => {
          setLoading(false);
      }, 500);
  }, [])

  // const navigate =useNavigate();
  const loadingCSS = {
      height:"100vh",
      backgroundColor:"#ggg",
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
  }




  return (
    <>
    {
      loading ? 
      (
        <section style={loadingCSS} className='loading-section'>
                    <BarLoader color={"#004D56"} loading={loading} size={100} />
        </section>  
      ) :
      (
        <div>
        
        <Helmet>
          <title>Shamgar Staines</title>
          {/* <meta name="description" content="Kommerla Shamgar Staines is a Site Reliability Engineer at ORACLE." /> */}
          <link rel="canonical" href="/" />
        </Helmet>

        <Nav />
        <Main />    
        <About />  
        <Work />
        <Education />
        {/* <Skills /> */}
        {/* <Verse /> */}
        <Contact />
        <Footer />
      </div>
      )
    }
      
    </>


    
  )
}

export default Home;