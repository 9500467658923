import React, { useRef,useEffect, useState } from 'react';
import Footer from './Footer';
import Nav from './Nav';
import { FaLinkedinIn, FaPhoneAlt, FaGithub, FaGlobe } from 'react-icons/fa';
import { GoMail } from "react-icons/go";
import BarLoader from "react-spinners/BarLoader";
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const ContactDet = () => {
    const navigate = useNavigate();
    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
      
      emailjs.sendForm('service_mj19zw1', 'template_qer07r8', form.current, 'v0zHcQb_PBUuz2a9w')
        .then((result) => {
            console.log(result.text);
            navigate('/success');
  
  
        }, (error) => {
            console.log(error.text);
            navigate('/failure');
        });
        
    };

    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 300);
    }, [])
  
    // const navigate =useNavigate();
    const loadingCSS = {
        height:"100vh",
        backgroundColor:"#ggg",
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    }


  return (

    <>
    {      
      loading ? 
      (
        <section style={loadingCSS} className='loading-section'>
                    <BarLoader color={"#004D56"} loading={loading} size={100} />
        </section>  
      ) :(
<div>
<Helmet>
          <title>contact me</title>
          {/* <meta name="description" content="Kommerla Shamgar Staines is a Site Reliability Engineer at ORACLE." /> */}
          <link rel="canonical" href="/contact" />
        </Helmet>
    <Nav  />
    <div className="p-12 bg-gray-200">
        <center>
        <h1 className="text-4xl md:text-5xl text-greenDarK">Contact <span className="font-thin">Me?? there are ways...</span></h1>
        </center>
        
    </div>
      <section className="w-screen md:flex justify-between">
      <div className="bg-gray-200 basis-1/2 flex flex-col justify-center items-center p-12">
        <h1 className="text-4xl lg:text-5xl font-semibold text-greenDarK mb-8">
          Write to me
        </h1>
        <form ref={form} className="flex flex-col w-full lg:w-3/4 gap-4" onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Name" id="name" className="input-text" required />
          <input type="email" name="email" placeholder="Email" id="email" className="input-text" required/>
          <textarea name="message" placeholder="Write your message here" id="message" cols="30" rows="10" className="input-text" required></textarea>
          <button type="submit" className="bg-greenDarK px-4 py-2 w-1/2 text-white rounded-md">Send</button>
        </form>
    
      </div>
        <div className="basis-1/2 bg-greenDarK flex flex-col justify-center p-12">
            
            {/* <h1 className="text-white text-4xl font-semibold mb-6">Here are my contact details</h1> */}
            
            {/* <div className="text-white mb-8">
                <h1 className="text-xl">ADDRESS</h1>
                <p className="italic font-light md:font-normal">
                    H.No: 4-53A, Oleru <br />
                    Bhattiprolu, Bapatla, <br />
                    Andhre Pradesh - 522265
                </p>
            </div> */}

            <div className="h1 text-white font-semibold text-2xl mb-4">Other Details:</div>
            <div className="flex flex-col justify-start">
              <div className="mb-8">
                  {/* <p className="text-white flex items-center"> <FaPhoneAlt /><span className="text-base ml-4 font-light md:font-normal">+91 6291165988</span></p> */}
                  <p className="text-white flex items-center "><GoMail className="align-middle" /> <a className=" font-light md:font-normal text-base ml-4" href="mailto:shamgar.kommerla@gmail.com">shamgar.kommerla@gmail.com</a></p>
                  <p className="text-white flex items-center "><FaGlobe className="align-middle" /><a href="https://shamgarstaines.com/" target="_blank" rel="noreferrer" className="text-base ml-4 font-light md:font-normal">www.shamgarkommerla.com</a></p>
                  <p className="text-white flex items-center "><FaGlobe className="align-middle" /><a href="https://shamgarstaines-old.netlify.app" target="_blank" rel="noreferrer" className="text-base ml-4 font-light md:font-normal">shamgarstaines-old.netlify.app</a></p>
              </div>
            
            
            <div className="text-white">
                <h1 className="mb-2 text-xl">Also find me on</h1>
                <div className="flex justify-between w-1/4 font-light md:font-normal">
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/shamgar-kommerla"><FaLinkedinIn /></a>
                    {/* <a href="#" target="_blank" rel="noreferrer"><FaTelegramPlane /></a> */}
                    <a href="mailto:shamgar.kommerla@gmail.com" target="_blank" rel="noreferrer"><GoMail /></a>
                    <a href="https://github.com/shamgar-kommerla" target="_blank" rel="noreferrer"><FaGithub /></a>
                    
                </div>
                
            </div>
            </div>
        </div>
      </section>
    <Footer />
    </div>
      )

    
  }
    </>

    
  );
};

export default ContactDet;
