import React from 'react'

const About = () => {
  return (
    <section className='bg-gray-200 p-8 md:p-16'>
        <div className='mx-auto flex justify-center flex-col items-center max-w-xl md:max-w-5xl'>
            <h1 className="font-bold text-4xl lg:text-5xl text-greenDarK mb-6"><span className='font-thin'>Who</span> Am <span className='font-thin'>I</span></h1> 
        <p className='text-center font-light mb-4 max-w-lg md:max-w-4xl lg:text-lg'>
        I am Kommerla Shamgar Staines, a Site Reliability Engineer at Oracle. I am a part of the SaaS Observability team. I work majorly on Oracle Cloud, Docker, Kubernetes, Prometheus, grafana, Oracle Enterprise manager, etc. Apart from this, I also am an amateur MERN Stack Developer. I love developing websites and trying new technologies in the Web D world. But currently, I am more into exploring Cloud.
        </p>
        <p className='text-center  font-light max-w-lg md:max-w-4xl lg:text-lg'>
        I completed my B.tech in Computer Science & Technology from Indian Institute of Engineering Science & Technology, Shibpur. In my free time, I read books(mostly religious) or listen to songs.
        </p>
    </div>
    </section>
  )
}

export default About