import React from 'react'


const Education = () => {
  return (
    <section className='bg-gray-200 px-6 py-14 md:p-14 lg:p-16'>
        <div className='mx-auto flex justify-center flex-col items-center max-w-5xl'>
        <h1 className="font-semibold text-greenDarK mb-6 text-4xl lg:text-5xl"><span className='font-thin'>My</span> Education</h1> 
        <ul className='list-none lg:w-5xl mx-auto'>
            {/* Bachelors */}
            <li className='flex justify-between space-x-4 max-w-3xl mb-4'>
               <div className='basis-1/3'><p className="text-lg font-medium">2018 - 2022</p>
                <h1 className='text-lg font-light'>Bachelor of Technology</h1>
                </div>
               <div className='basis-2/3'>
                    
                    <h1 className='text-lg font-medium'>Indian Institute of Engineering Science & Technology, Shibpur</h1>
                    <p className="font-light"> Computer Science & Technology</p>
                    <p className="font-light"><span className="font-normal">CGPA: </span> 7.13</p>
                </div> 
            </li>

            <li className='flex justify-between space-x-4 max-w-3xl mb-4'>
               <div className='basis-1/3'><p className="text-lg font-medium">2016 - 2018</p>
                <h1 className='text-lg font-light'>Intermediate Education</h1>
                </div>
               <div className='basis-2/3'>
                    
                    <h1 className='text-lg font-medium'>Naranaya Junior College, Nidamanauru</h1>
                    <p className="font-light"> MPC (Mathematics Physics Chemistry)</p>
                    <p className="font-light"><span className="font-normal">Percentage: </span> 97.3%</p>
                </div> 
            </li>

            <li className='flex justify-between space-x-4 max-w-3xl mb-4'>
               <div className='basis-1/3'><p className="text-lg font-medium">2015 - 2016</p>
                <h1 className='text-lg font-light'>High School (SSC) </h1>
                </div>
               <div className='basis-2/3'>
                    
                    <h1 className='text-lg font-medium'>Montfort High School, Repalle</h1>
                    <p className="font-light">Secondary School Certicficate</p>
                    <p className="font-light"><span className="font-normal">GPA: </span> 10.0</p>
                </div> 
            </li>

        </ul>
    </div>
    </section>
  )
}

export default Education