import React from "react";
import {BrowserRouter, Route,Routes} from "react-router-dom";
import ContactDet from "./Components/ContactDet";
import CV from "./Components/CV";
import Home from './Components/Home'
import Nav from "./Components/Nav";
import Failed from './assets/img/close.png';
import Success from './assets/img/check-mark.png';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function MyRoutes() {

    const Sorry = () => {

        // const navigate = useNavigate();


        // setTimeout(() => {
        //     navigate('/');
        // }, 4000);
            
        return (
            <div>
                <Helmet>
                    <title>Failure</title>
                    {/* <meta name="description" content="Kommerla Shamgar Staines is a Site Reliability Engineer at ORACLE." /> */}
                    <link rel="canonical" href="/failure" />
                </Helmet>
                <Nav />
                <div className="maeibccbdgndx-w-sm mx-auto flex flex-col justify-center items-center">
                    <img src={Failed} className="mb-4" alt=""  width="100"/>
                    <p className="text-xl font-semibold text-red-600">Sorry!! Something went wrong!! Please try again later.</p>
                    <br /><br /><br />
                    <p>Go back to <Link to="/" className="text-lg text-blue-700 underline">Homepage</Link> </p>
                </div>
            </div>
        )
    }

    const Thank = () => {
        // const navigate = useNavigate();

        // setTimeout(() => {
        //     navigate('/');
        // }, 4000);

        return (
            <div>
            <Helmet>
                    <title>Success</title>
                    {/* <meta name="description" content="Kommerla Shamgar Staines is a Site Reliability Engineer at ORACLE." /> */}
                    <link rel="canonical" href="/success" />
                </Helmet>
            <Nav />
            <div className="max-w-sm mx-auto flex flex-col justify-center items-center">
                <img src={Success} className="mb-4" alt=""  width="100"/>
                <p className="text-xl font-semibold text-green-600">Thank you for reaching out, will get back to you soon...</p>
                <br /><br /><br />
                <p>Go to <Link to="/" className="text-lg text-blue-700 underline">Homepage</Link> </p>
            </div>
        </div>
        )
      }

    
    return (
        <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/cv" element={<CV />} />
            <Route exact path="/contact" element={<ContactDet />} />
            <Route exact path="/success" element={<Thank />} />
            <Route exact path="/failure" element={<Sorry />} />
        </Routes>
        </BrowserRouter>
    )
}




