import React from 'react'
// import one from '../assets/skills/001.svg'
// import two from '../assets/skills/002.svg'
// import three from '../assets/skills/003.svg'
import docker from '../assets/skills/docker_3.png';
import mern from '../assets/skills/mermn.png';
import cloud from '../assets/skills/cloud.png';
import kub from '../assets/skills/kub.png'
const Skills = () => {
  return (
    <section className='w-screen bg-gray-200 p-12 flex justify-evenly items-center flex-col'>
      {/* <h1 className="font-semibold text-5xl text-greenDarK mb-6">Tech <span className='font-thin'>Stack</span></h1>  */}
        {/* <div className="hidden lg:flex max-w-7xl mx-auto gap-8  flex-wrap justify-evenly">
            <img src={docker} alt="" width="200" className="object-contain" />
            <img src={cloud} alt="" width="200" className="object-contain" />
            <img src={mern} alt="" width="200" className="object-contain"  />
            <img src={kub} alt="" width="200" className="object-contain" />
        ˝</div> */}

        <div className="lg:hidden flex max-w-7xl mx-auto gap-8  flex-wrap justify-evenly">
            <img src={docker} alt="" width="120" className="object-contain" />
            <img src={cloud} alt="" width="120" className="object-contain" />
            <img src={mern} alt="" width="120" className="object-contain"  />
            <img src={kub} alt="" width="120" className="object-contain" />
        </div>


    </section>
  )
}

export default Skills
