import React from 'react'
import myImg from '../assets/img/001.jpg';

const Main = () => {
  return (
    <section>
        <div className='md:flex'>
            <div className='basis-1/2 bg-greenDarK py-14 px-8 md:p-8 flex justify-center items-center'>
              <div className=''>
                <p className='text-white text-2xl md:text-3xl lg:text-4xl font-light mb-2 md:mb-6'>Hey, I'm</p>
                <h1 className='text-white text-5xl md:text-7xl lg:text-8xl font-bold'>Shamgar</h1>
                <h1 className='text-white text-5xl md:text-7xl lg:text-8xl font-bold md:mb-2'>Kommerla</h1>            
                <p className='text-white text-sm md:text-md lg:text-lg font-light'>Site Reliability Engineer at ORACLE</p>
              </div>
            </div>

            <div className="basis-1/2 h-screen">
              {/* <div className="h-full w-full hero-img-sec"></div> */}
                <img src={myImg} alt="" className='object-cover w-full h-full'  />
            </div>
        </div>
    </section>
    
  )
}

export default Main