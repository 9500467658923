import React from 'react'

const Work = () => {
  return (
    <section className='bg-gray-200   px-6 py-14 md:p-14 lg:p-16'>
        <div className='mx-auto flex justify-center flex-col items-center max-w-5xl'>
        <h1 className="font-semibold text-greenDarK mb-6 text-4xl lg:text-5xl"><span className='font-thin'>Work</span> Experience</h1> 
        <ul className='list-none w-4xl'>
            <li className='flex justify-between space-x-4 md:max-w-2xl'>
               <div className='basis-1/3'><p className="text-lg font-medium">2022 - present</p>
                <h1 className='ttext-lg font-light'>Site Reliability Engineer</h1>
                </div>
               <div className='basis-2/3'>
                    <h1 className='text-base md:text-lg font-semibold'>ORACLE INDIA PVT. LTD</h1>
                    <p>SaaS Observabiity. I work majorly on Oracle Cloud, Docker, Kubernetes, prometheus, grafana, Oracle Enterprise manager, etc.</p>
                </div> 
            </li>

        </ul>
    </div>
    </section>
  )
}

export default Work