import React from 'react'

const Footer = () => {
  return (
    <section className='h-32 bg-greenDarK flex flex-col justify-center items-center'>
      <h1 className='text-white font-light text-lg'>shamgarstaines &copy; 2023</h1>
    </section>
  )
}

export default Footer;