import React, { useRef, } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';



const Contact = () => {
  const navigate = useNavigate();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs.sendForm('service_mj19zw1', 'template_qer07r8', form.current, 'v0zHcQb_PBUuz2a9w')
      .then((result) => {
          console.log(result.text);
          navigate('/success');


      }, (error) => {
          console.log(error.text);
          navigate('/failure');
      });
      
  };



  return (
    <section className="w-screen md:flex justify-between">
      <div className="bg-gray-200 basis-1/2 flex flex-col justify-center items-center p-12">
        <h1 className="text-4xl lg:text-5xl font-semibold text-greenDarK mb-8">
          Write to me
        </h1>
        <form ref={form} className="flex flex-col w-full lg:w-3/4 gap-4" onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Name" id="name" className="input-text" required />
          <input type="email" name="email" placeholder="Email" id="email" className="input-text" required/>
          <textarea name="message" placeholder="Write your message here" id="message" cols="30" rows="10" className="input-text" required></textarea>
          <button type="submit" className="bg-greenDarK px-4 py-2 w-1/2 text-white rounded-md">Send</button>
        </form>
    
      </div>
      <div className="basis-1/2 bg-greenDarK text-white flex flex-col items-center p-8 lg:p-12">
        <div className="w-5/6 lg:w-2/3 m-auto ">
          <p className="font-medium text-4xl md:text-5xl verse-styles">
            " I can do all things through Christ who strengthens me "
          </p> <br />
          <p className="float-right text-2xl md:text-3xl verse-styles">- Philippians 4:13 <span className="verse-styles">( Bible )</span></p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
