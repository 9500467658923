// import { directive } from '@babel/types'
import React, { useEffect, useState } from 'react'
import Nav from "./Nav";
import Footer from "./Footer";
import Work from "./Work";
import Education from "./Education";
import Certifications from "./Certifications";
import Skills from "./Skills";
import pass from '../assets/img/pass.jpg';
import BarLoader from "react-spinners/BarLoader";
// import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';


const CV = () => {
  
  const [loading, setLoading] = useState(true);
    
  useEffect(() => {
      setLoading(true);
      setTimeout(() => {
          setLoading(false);
      }, 500);
  }, [])

  // const navigate =useNavigate();
  const loadingCSS = {
      height:"100vh",
      backgroundColor:"#ggg",
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
  }

  
  return (

    <>
    {      
      loading ? 
      (
        <section style={loadingCSS} className='loading-section'>
                    <BarLoader color={"#004D56"} loading={loading} size={100} />
        </section>  
      ) :
      (
        <div>
           <Helmet>
          <title>professional summary</title>
          {/* <meta name="description" content="Kommerla Shamgar Staines is a Site Reliability Engineer at ORACLE." /> */}
          <link rel="canonical" href="/cv" />
        </Helmet>
      <Nav />
      <section className="bg-gray-200">
        <div className="p-12 max-w-6xl mx-auto">
          <h1 className="text-4xl font-thin lg:text-5xl mb-12 text-greenDarK">
            <span className="font-semibold">Professional</span> Summary
          </h1>
          <div className="flex items-start justify-evenly md:max-w-5xl">
            <div className="hidden lg:block md:basis-1/5">
              <img src={pass} alt="" className="h-5/6 rounded-full"/>
            </div>
            <div className=" lg:basis-3/5 ml-4">
              <p className="font-light mb-5">
              I am Kommerla Shamgar Staines, a Site Reliability Engineer at Oracle. I am a part of the SaaS Observability team. I work majorly on Oracle Cloud, Docker, Kubernetes, Prometheus, grafana, Oracle Enterprise manager, etc. Apart from this, I also am an amateur MERN Stack Developer. I love developing websites and trying new technologies in the Web D world. But currently, I am more into exploring Cloud.
              </p>
              
              <a href="https://drive.google.com/file/d/1ZQRoOJB3LDbjMNXyLAf4hAGL-u9AGu9z/view?usp=sharing" className="text-white bg-yellow-500 px-4 py-2 rounded-md">Download CV</a>
            </div>
            
            
          </div>
        </div>
      </section>
      <Work />
      <Certifications />
      <Education />
      <Skills />
      <Footer />
        </div>
      )
    }
    </>
  );
};

export default CV;
