import React from 'react'
import Bootstr from '../assets/certifications/bootstrap.jpg'
import Resp from '../assets/certifications/Resp_WEB.jpg';
import Mern from '../assets/certifications/MERN.png';

const Certifications = () => {
  return (
    <section className="p-8 lg:p-12 bg-gray-200">
        <center>
        <h1 className="text-4xl lg:text-5xl max-w-6xl mx-auto text-greenDarK font-bold mb-8">Certifications </h1>
        </center>
        

        {/* Mobile */}
        <div className="flex lg:hidden max-w-2xl lg:max-w-7xl mx-auto gap-2 lg:gap-4 flex-wrap justify-evenly">
            {/* card */}

            <a href="#" className="">
                <img src={Resp} alt="" width="300" />
            </a>

            <a href="#" target="_blank" className="">
                <img src={Bootstr} alt="" width="300" />
            </a>

            {/* <a href="#" className="">
                <img src={Resp} alt="" width="300" />
            </a> */}
        

            <a href="#" target="_blank" className="">
                <img src={Mern} alt="" width="300" />
            </a>
            {/* <a href="#" target="_blank" className="certificate">
                <img src={Bootstr} alt="" width="300" className="cert-img" />
                <div className="overlay"></div>
            </a>

            <a href="#" className="">
                <img src={Resp} alt="" width="300" />
            </a> */}
            
            
        
        </div>

        {/* Desktop  */}
        <div className="hidden lg:flex max-w-2xl lg:max-w-7xl mx-auto gap-2 lg:gap-4 flex-wrap justify-evenly">
            {/* card */}



            <a href="#" target="_blank" className="certificate">
                <img src={Mern} alt="" width="400" className="cert-img"  />
                <div className="overlay">
                    <p>
                        MERN Bootcamp
                    </p>
                    
                </div>
            </a>
            
            <a href="#" target="_blank" className="certificate">
                <img src={Bootstr} alt="" width="400" className="cert-img" />
                <div className="overlay">
                    <p>
                        Front-End Web UI Frameworks and Tools: Bootstrap
                    </p>
                    
                </div>
            </a>
            
            <a href="#" className="certificate">
                <img src={Resp} alt="" width="400" className="cert-img"  />
                <div className="overlay">
                    <p>
                        Responsive Web Development
                    </p>
                    
                </div>
            </a>
        </div>

    </section>
  )
}

export default Certifications